import algoliasearch from "algoliasearch/lite";
import { createRef, default as React, useState } from "react";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import SearchBox from "./box";
import SearchResult from "./result";
import useClickOutside from "./use-click-outside";

export default function Search({ loseFocusCallback, searchSuggestions = [] }) {
  const indices = [{ name: `Posts`, title: `Posts` }];

  const rootRef = createRef();
  const [query, setQuery] = useState();
  const [hasFocus, setFocus] = useState(loseFocusCallback ? true : false);

  // If loseFocusCallback is set it's the nav search box
  // Autofocus the search box on load
  const autofocus = loseFocusCallback ? true : false;

  const algoliaClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  const searchClient = {
    search(requests) {
      if (requests[0].params.query === "") {
        return [];
      }
      return algoliaClient.search(requests);
    },
  };

  useClickOutside(rootRef, () => {
    setFocus(false);
    if (loseFocusCallback) {
      loseFocusCallback();
    }
  });

  return (
    <div ref={rootRef} className="search" data-focus={hasFocus}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <SearchBox
          onFocus={() => setFocus(true)}
          hasFocus={hasFocus}
          delay={500}
          autofocus={autofocus}
          searchSuggestions={searchSuggestions}
        />
        <SearchResult
          show={query && query.length > 0 && hasFocus}
          indices={indices}
          className="search-results"
        />
        <Configure clickAnalytics />
      </InstantSearch>
    </div>
  );
}
