import colorContrast from "color-contrast";

const white = "#fff";
const black = "#000";
const purple = "#201c50";
const purpleLight = "#4d437d";
const purpleWash = "#f5f4f7";
const blackSecondary = "#706d6e";

const getForegroundColor = background => {
  const whiteContrast = colorContrast(background, white);
  return whiteContrast > 3 ? white : black;
};

export {
  getForegroundColor,
  white,
  black,
  purple,
  purpleLight,
  purpleWash,
  blackSecondary,
};
