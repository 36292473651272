import React from "react";
import { Link } from "gatsby";
import { Article, Event, Resource } from "../../util/icons";
import { purple } from "../../util/colors";

const PostTaxonomy = ({ post, clickable = false }) => {
  const icons = {
    article: <Article className="icon icon--inline" />,
    event: <Event className="icon icon--inline" />,
    resource: <Resource className="icon icon--inline" />,
  };

  const color = post.category
    ? post.category.color.hex || post.category.color
    : "";
  return (
    <ul className="post-taxonomy">
      {post._type && (
        <li
          className="type"
          key={`type-${post._type}`}
          style={{ "--fill": purple }}
        >
          {icons[post._type]}
          <span>{post._type}</span>
        </li>
      )}
      {post.category && (
        <li
          className="category"
          key={`category-${post.category.title}`}
          style={{
            "--color": color,
          }}
        >
          {clickable ? (
            <Link
              to={`/content-finder/?category=${
                post?.category?.slug?.current || post?.category?.slug
              }`}
            >
              {post.category.title}
            </Link>
          ) : (
            <span>{post.category.title}</span>
          )}
        </li>
      )}
      {post.tags &&
        post.tags.length > 0 &&
        post.tags.map((tag, i) => (
          <li className="tag" key={`tag-${i}`}>
            {clickable ? (
              <Link
                to={`/content-finder/?tag=${tag?.slug?.current || tag?.slug}`}
              >
                {tag.title}
              </Link>
            ) : (
              <span>{tag.title}</span>
            )}
          </li>
        ))}
    </ul>
  );
};

export default PostTaxonomy;
