import React from "react";
import Image from "./image";
import urlParser from "js-video-url-parser/lib/base";
import "js-video-url-parser/lib/provider/youtube";
import YouTube from "react-youtube";

class Video extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showVideo: false };
    this.loadVideo = this.loadVideo.bind(this);
  }

  loadVideo() {
    this.setState({
      showVideo: true,
    });
  }

  render() {
    const { youtube, options, image, aspectRatio } = this.props;
    const data = urlParser.parse(youtube);

    if (data && data.id) {
      let img;
      if (image) {
        img = (
          <Image
            image={image}
            options={options}
            aspectRatio={aspectRatio}
          ></Image>
        );
      } else {
        img = (
          <img src={`https://img.youtube.com/vi/${data.id}/0.jpg`} alt=""></img>
        );
      }

      // Get video start time
      const start = data?.params?.start || 0;

      const video = (
        <YouTube
          videoId={data.id}
          classContainerName="youtube"
          opts={{
            playerVars: {
              autoplay: 1,
              modestbranding: 1,
              playsinline: 1,
              rel: 0,
              start: start,
            },
          }}
        />
      );

      return (
        <figure className="video">
          {!this.state.showVideo && (
            <button onClick={this.loadVideo}>{img}</button>
          )}
          {this.state.showVideo && video}
        </figure>
      );
    } else {
      return "";
    }
  }
}
export default Video;
