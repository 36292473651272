import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { white } from "../../util/colors";
import { Search as SearchIcon } from "../../util/icons";

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.timerId = null;
    this.onFocus = props.onFocus;
    this.autofocus = props.autofocus;
    this.searchSuggestions = props.searchSuggestions;

    this.state = {
      value: this.props.currentRefinement,
    };

    this.inputRef = React.createRef();
  }

  onChangeDebounced = event => {
    const { refine, delay } = this.props;
    const value = event.currentTarget.value;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => refine(value), delay);

    this.setState(() => ({
      value,
    }));
  };

  onSuggestionClick = e => {
    const { refine } = this.props;
    const value = e.target.innerText;
    // e.preventDefault();
    this.setState(() => ({
      value: value,
    }));
    this.inputRef.current.focus();
    refine(value);
    trackCustomEvent({
      category: "Search",
      action: "Suggestion Click",
      label: value,
    });
  };

  render() {
    const { value } = this.state;

    return (
      <>
        <section className="search-box">
          <label htmlFor="search">Search</label>
          <input
            autoFocus={this.autofocus}
            autoComplete="off"
            type="text"
            name="search"
            id="search"
            placeholder="Start exploring Flying Colours"
            aria-label="Search"
            onChange={this.onChangeDebounced}
            value={value}
            onFocus={this.onFocus}
            ref={this.inputRef}
          ></input>
          <div className="input-icon">
            <SearchIcon className="icon" style={{ "--fill": white }} />
          </div>
        </section>
        {this.searchSuggestions.length > 0 && (
          <div className="search-suggestions">
            <span>Recently popular: </span>
            <ul>
              {this.searchSuggestions.map((suggestion, i) => {
                return (
                  <li key={i}>
                    <button onClick={this.onSuggestionClick}>
                      {suggestion}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </>
    );
  }
}

const DebouncedSearchBox = connectSearchBox(SearchBox);

export default DebouncedSearchBox;
