import React from "react";
import { Link } from "gatsby";

const Nav = () => {
  
  const navItems = [
    {
      title: "All about deafness",
      href: "/all-about-deafness/",
    },
    {
      title: "Link Up & Events",
      href: "/link-up/",
    },
    {
      title: "Research insights",
      href: "/research-insights/",
    },
    {
      title: "Service finder",
      href: "/service-finder/",
    },
    {
      title: "Content finder",
      href: "/content-finder/",
    },
    {
      title: "Resources",
      href: "/resources/",
    },
    {
      title: "Contact us",
      href: "/contact/",
    },
    {
      title: "About",
      href: "/about/",
    },
  ];
  return (
    <nav>
      <ul>
        {navItems.map((item, i) => {
          let className = "";
          return (
            <li key={i} className={className}>
              <Link to={item.href}>{item.title}</Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};


export default Nav;
