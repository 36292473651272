import React from "react";
import { Link } from "gatsby";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { white } from "../../util/colors";
import Nav from "./nav";
import Message from "../footer/message";
import Search from "../search";
import { Search as SearchIcon } from "../../util/icons";

function Header({ navIcon, navSearch }) {
  const [menuActive, setMenuActive] = React.useState(false);
  const [searchActive, setSearchActive] = React.useState(false);

  const onMenuClick = () => {
    setMenuActive(!menuActive);
    trackCustomEvent({
      category: "Nav",
      action: menuActive ? "Close Mobile Nav" : "Open Mobile Nav",
    });
  };

  const onSearchClick = () => {
    setSearchActive(true);
    trackCustomEvent({
      category: "Nav",
      action: "Open Search Box",
    });
  };

  const onSearchClose = () => {
    setSearchActive(false);
    trackCustomEvent({
      category: "Nav",
      action: "Close Search Box",
    });
  };

  let className = `logo`;
  if (navIcon === white) {
    className += " logo--white";
  } else if (navIcon === "black") {
    className += " logo--black";
  }

  let iconSrc = "/img/logo-light.svg";
  if (navIcon === "dark") {
    iconSrc = "/img/logo-dark.svg";
  }

  const headerClass = `header header-menu--${
    menuActive ? "open" : "closed"
  } header-search--${searchActive ? "open" : "closed"}`;
  const logo = (
    <img
      src={menuActive ? "/img/logo-light.svg" : iconSrc}
      alt="Flying Colours"
      className={className}
    />
  );

  return (
    <header className={headerClass}>
      <Link to="/">{logo}</Link>
      <Nav />
      <div className="controls">
        {navSearch && (
          <>
            {searchActive ? (
              <Search loseFocusCallback={onSearchClose} />
            ) : (
              <button className="search-button" onClick={onSearchClick}>
                <SearchIcon className="icon" />
                <span>Search</span>
              </button>
            )}
          </>
        )}
        <button className="menu" onClick={onMenuClick}>
          {menuActive ? "Close" : "Menu ..."}
        </button>
      </div>
      {menuActive && <Message />}
    </header>
  );
}

export default Header;
