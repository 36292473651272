import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { white } from "../../util/colors";
import { Send } from "../../util/icons";

function Join() {
  const formContainer = React.createRef();
  const mailchimpUrl = process.env.GATSBY_MAILCHIMP_ACTION_URL;

  const CustomForm = ({ status, message, onValidated }) => {
    let email;

    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });

    return (
      <div className="mailchimp-subscribe">
        <input
          type="email"
          placeholder="youremail@internet.com.au"
          aria-label="Email"
          ref={node => (email = node)}
        ></input>
        <button onClick={submit}>
          <Send className="icon" style={{ "--fill": white }} />
          <span>Submit</span>
        </button>
        <div className="result">
          {status === "error" && (
            <span dangerouslySetInnerHTML={{ __html: message }} />
          )}
          {status === "success" && <span>Thanks for signing up!</span>}
        </div>
      </div>
    );
  };

  return mailchimpUrl ? (
    <section className="footer-join">
      <div className="container">
        <div className="form-container" ref={formContainer}>
          <h2>Join the community</h2>
          <p>
            Sign up to our mailing list with the latest news and events from
            Deaf Children Australia.
          </p>
          <MailchimpSubscribe
            url={mailchimpUrl}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
        </div>
        <StaticImage
          src="../../../static/img/platy-mumboy-reading.png"
          alt=""
          placeholder="tracedSVG"
          layout="fullWidth"
          objectFit="contain"
          objectPosition="50% 100%"
        />
      </div>
    </section>
  ) : null;
}

export default React.memo(Join);
