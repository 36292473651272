import React from "react";
// import { useStaticQuery, graphql } from "gatsby";
import Join from "./join";
import Message from "./message";
import { white } from "../../util/colors";
import { Twitter, Instagram, Facebook } from "../../util/icons";
const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         contact {
  //           social {
  //             platform
  //             title
  //             url
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  // const social = data.site.siteMetadata.contact.social;
  const social = [];

  const icons = {
    twitter: <Twitter className="icon" />,
    facebook: <Facebook className="icon" />,
    instagram: <Instagram className="icon" />,
  };

  return (
    <footer className="footer">
      <Join />
      <section className="footer-links">
        <div className="container">
          {social.length > 0 && (
            <ul>
              {social.map(item => {
                return (
                  <li key={item.platform}>
                    <a
                      target="_blank"
                      href={item.url}
                      rel="noreferrer"
                      style={{ "--fill": white }}
                    >
                      {icons[item.platform]}
                      <span>{item.title}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          <Message />
        </div>
      </section>
    </footer>
  );
};

export default Footer;
