import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "../../../sanity.json";

const Image = ({ image, options, rounded, block, aspectRatio }) => {
  if (!image) {
    return null;
  }

  const style = {};
  if (block) {
    style.display = "block";
  }

  if (aspectRatio) {
    style.paddingTop = `${(aspectRatio.h / aspectRatio.w) * 100}%`;
    style.height = 0;
  }

  let objectPosition = "50% 50%";
  if (image.hotspot) {
    objectPosition = `${image.hotspot.x * 100}% ${image.hotspot.y * 100}%`;
  }

  const imageData = getGatsbyImageData(
    image.asset ? image.asset : image,
    options,
    sanityConfig
  );

  return (
    <GatsbyImage
      image={imageData}
      alt={image.altText || ""}
      className={rounded ? "-rounded" : ""}
      style={style}
      objectPosition={objectPosition}
    />
  );
};

export default Image;
