import { Link } from "gatsby";
import React from "react";
import { Hits, Index, connectHitInsights } from "react-instantsearch-dom";
import { parse, format } from "date-fns";
import Image from "../blocks/image";
import PostTaxonomy from "../posts/taxonomy";

const PageHit = ({ hit, insights }) => {
  const imageOptions = {
    width: 100,
    height: 100,
    placeholder: "none",
    layout: "fixed",
  };

  let date;
  if (hit._type === "event") {
    const d = hit.date ? hit.date : hit.eventDates.start;
    const parsed = parse(d, "yyyy-MM-dd", new Date());
    date = format(parsed, "d MMM");
  }

  return (
    <div className="post-tile">
      <div className="wrapper">
        <div aria-hidden={true}>
          <Link
            to={hit.path}
            onClick={() => {
              // Algolia analytics tracking event
              insights("clickedObjectIDsAfterSearch", {
                eventName: "Link Clicked",
                index: "Posts",
                objectIDs: [hit.objectID],
              });
            }}
            className="image"
            tabIndex="-1"
          >
            <Image
              image={hit.poster}
              options={imageOptions}
              rounded={true}
              aspectRatio={{ w: 1, h: 1 }}
            ></Image>
            {date && <span>{date}</span>}
          </Link>
        </div>
        <div className="info">
          <Link
            to={hit.path}
            className="title"
            onClick={() => {
              // Algolia analytics tracking event
              insights("clickedObjectIDsAfterSearch", {
                eventName: "Link Clicked",
                index: "Posts",
                objectIDs: [hit.objectID],
              });
            }}
          >
            <p>{hit.title}</p>
          </Link>
          <p className="subtitle">{hit.subtitle}</p>
          <PostTaxonomy post={hit} clickable={true} />
        </div>
      </div>
    </div>
  );
};

// Algolia tracking won't work from SSR
let PageHitWithInsights =
  typeof window !== "undefined"
    ? connectHitInsights(window.aa)(PageHit)
    : PageHit;

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <Hits className="hits" hitComponent={PageHitWithInsights} />
  </Index>
);

const SearchResult = ({ indices, className, show }) => {
  if (show) {
    return (
      <div className={className}>
        {indices.map(index => (
          <HitsInIndex index={index} key={index.name} />
        ))}
      </div>
    );
  }

  return "";
};

export default SearchResult;
