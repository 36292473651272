import React from "react";
import "../styles/main.css";
import { getForegroundColor, white, purple, purpleWash } from "../util/colors";

import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";

import Header from "./header/";
import Footer from "./footer/";

const Layout = ({ children, className, backgroundColor, navSearch = true }) => {
  let style = {
    "--foreground-color": purple,
    "--background-color": purpleWash,
    "--nav-foreground-color": purple,
  };

  // Choose colours for hero / nav
  let navIcon = "dark";
  if (className === "home") {
    style = {
      "--foreground-color": white,
      "--background-color": purpleWash,
      "--nav-foreground-color": "black",
    };
  } else if (backgroundColor) {
    const foregroundColor = getForegroundColor(backgroundColor);
    const navColor = foregroundColor === white ? foregroundColor : "black";

    style = {
      "--foreground-color": foregroundColor,
      "--background-color": backgroundColor,
      "--nav-foreground-color": navColor,
    };
    navIcon = backgroundColor === purple ? "light" : navColor;
  }

  return (
    <div className={className} style={style}>
      <SkipNavLink />
      <Header navIcon={navIcon} navSearch={navSearch} />
      <main>
        <SkipNavContent />
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
