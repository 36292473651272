import React from "react";
import Image from "./image";

const Figure = ({ figure, options, aspectRatio }) => {
  let image;

  if (figure.image) {
    image = (
      <Image
        image={figure.image}
        options={options}
        aspectRatio={aspectRatio}
      ></Image>
    );
  }

  let figCaption;
  if (figure.caption || figure.source) {
    figCaption = (
      <figcaption>
        {figure.caption && <p className="caption">{figure.caption}</p>}
        {figure.source && (
          <p className="source">
            Source: <span>{figure.source}</span>
          </p>
        )}
      </figcaption>
    );
  }

  return (
    <figure>
      {image}
      {figCaption}
    </figure>
  );
};

export default Figure;
