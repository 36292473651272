import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import opengraph from "../../static/img/opengraph.png";
import sanityConfig from "../../sanity.json";

function SEO({ description, lang = "en", meta = {}, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  let ogImage = `${site.siteMetadata.siteUrl}${opengraph}`;
  if (image) {
    const imageData = getGatsbyImageData(
      image.asset,
      {
        width: 1200,
        height: 630,
        layout: "fixed",
      },
      sanityConfig
    );

    ogImage = imageData.images.fallback.src;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        title === site.siteMetadata.title
          ? `%s`
          : `%s | ${site.siteMetadata.title}`
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:image:width`,
          content: 1200,
        },
        {
          property: `og:image:height`,
          content: 630,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ].concat(meta)}
    />
  );
}

export default SEO;
