import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Link } from "gatsby";

const Message = () => {
  return (
    <div className="footer-message">
      <p>
        <span>Flying Colours</span> Another small step on the journey.
        <br />
        Brought to you by{" "}
        <OutboundLink href="https://www.deafchildrenaustralia.org.au/">
          Deaf Children Australia
        </OutboundLink>
        .
      </p>
      <img className="dca-logo" src="/img/dca.svg" alt=""></img>
      <p className="address">597 St Kilda Rd, Melbourne VIC 3004</p>
      <p className="privacy">
        <Link to={`/privacy-policy`}>Privacy Policy</Link>
      </p>
    </div>
  );
};

export default Message;
